import { HttpResponse } from '@angular/common/http';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { filter, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IButton } from '../model/button.model';
import { IHeader } from '../model/header.model';
import { IImageResponse } from '../model/image-response.model';
import { LISTA_PAIS, Pais } from '../model/pais';
import { UserCss } from '../model/user-css.model';
import { IUserProfileWithCSS } from '../model/user-profile-css.model';
import { UserUriRequest } from '../model/user-uri-request.model';
import { ModalComponent } from './change-contacts-modal/modal.component';
import { QRCodeModalComponent } from './qr-code/qrcode-modal-component.component';
import { UserProfileService } from './user-profile.service';
import { darken, desaturate, lighten, readableColor } from 'polished';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { IPublicPlan } from '../model/document-user.model';
import { IProfile } from '../model/document-user-profile.model';

interface ISendContactsForm {
  name: FormControl<string>;
  email: FormControl<string>;
  phone: FormControl<string>;
  company: FormControl<string | null>;
  acceptSendingEmails: FormControl<boolean>;
  countryPhone: FormControl<Pais | null>;
  position: FormControl<string | null>;
}

interface IFormattedButton extends IButton {
  formattedLink: SafeResourceUrl;
}

interface IFormattedProfile extends IProfile {
  emailContacts: any[];
  phoneContacts: any[];
  locations: any[];
}

interface ISelectedProfile {
  indicator: number;
  data: IFormattedProfile;
}

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
  sendContactsForm: FormGroup<ISendContactsForm>;
  baseUrlProfile: string = environment.baseurlLoginProfile;
  sendContactFormEmailError?: boolean = undefined;
  paises: Pais[] = LISTA_PAIS;
  textCopied: string = '';
  profiles: { name: string;  visible: boolean}[] = [];
  hasSomeUserVisible = false;
  selectedProfile!: ISelectedProfile;

  @ViewChild(ModalComponent) modalComponent: ModalComponent | undefined;

  toggleModal() {
    this.modalComponent?.toggle();
  }

  uri: string = "linkHttp1_3";
  userProfile: IUserProfileWithCSS | undefined = undefined;
  userCss: UserCss | undefined = undefined;
  botoes: IFormattedButton[] = [];
  emailContacts: string[] = [];
  showMoreEmailsPopupOpened = false;
  phoneContacts: string[] = [];
  showMorePhonesPopupOpened = false;
  locationContacts: string[] = [];
  showMoreLocationsPopupOpened = false;
  menuActionsPopupOpened = false;
  nome: string = '';
  biografia: string = '';
  principalEmailContact: string | null = null;
  principalPhoneContact: string | null = null;
  principalLocationContact: string | null = null;
  cabecalho: IHeader = { text: '', visible: true };
  imagemPerfilUrl = '/assets/img/imagem_perfil.png';
  imagemBackgroundUrl = '/assets/img/imagem_fundo.png';
  profileExists: boolean = false;
  perfilEmConstrucao: boolean = false;
  showImageProfile: boolean = false;

  screenHeight: any;
  screenWidth: any;

  urlOriginal: string = '';

  base64Image: string = '';

  addContact: boolean = false;
  changeContactsButtonOpened: boolean = true;

  profileLimits: IPublicPlan = {
    professionalView: false,
    leadsReceiveContact: 0,
    contactsDisplay: 0,
    socialLinksDisplay: 0,
    linksDisplay: 0
  };

  constructor(
    private perfilUsuarioService: UserProfileService,
    private router: Router,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private sanitizer: DomSanitizer
  ) {
    this.sendContactsForm = new FormGroup<ISendContactsForm>({
      name: new FormControl('', { nonNullable: true }),
      email: new FormControl('', { nonNullable: true, validators: [Validators.email, Validators.required] }),
      phone: new FormControl('', { nonNullable: true, validators: [Validators.required] },),
      company: new FormControl('', { nonNullable: false }),
      acceptSendingEmails: new FormControl(false, { nonNullable: true }),
      countryPhone: new FormControl(null, { nonNullable: true }) as FormControl<Pais | null>,
      position: new FormControl(null, { nonNullable: true }) as FormControl<string | null>,
    });

    const uriParts = this.router.url.split('?');
    this.urlOriginal = uriParts[0];
    this.uri = this.urlOriginal.replace('/', '');

    window.history.replaceState({}, '', this.urlOriginal);
    this.getScreenSize();
  }

  ngOnInit(): void {
    this.getUserByUri();
  }

  transformUrl(url: any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  openSendContactsModal() {
    this.modalComponent?.toggle();

    const brazil = LISTA_PAIS.find(country => country.sigla === 'BR');

    if (brazil) {
      this.sendContactsForm.controls['countryPhone'].setValue(brazil)
    } else {
      this.sendContactsForm.controls.phone.disable();
    }
  }

  getColorContrast(c: string, factor?: number) {
    if (c === 'none') {
      return '#000';
    }

    if (!factor) {
      factor = 0.1;
    }

    const readable = readableColor(c);

    if (readable === '#000') {
      return darken(factor, c);
    }

    return lighten(factor, c);
  }

  getProfileTabsBg(type: 'color' | 'bg' | 'border', selected: boolean) {
    if (type === 'bg') {
      let colorToCompare = this.userCss?.preenchimento;

      if (!colorToCompare || colorToCompare === 'none') {
        colorToCompare = '#6f45dc';
      }

      return selected ? colorToCompare : this.getColorContrast(colorToCompare);
    }

    if (type === 'border') {
      const boderColor = this.userCss?.espessura !== '0' && this.userCss?.contorno ? this.userCss?.contorno : this.getColorContrast(this.userCss?.preenchimento || '#6f45dc', 0.5);
      return !selected ? 'transparent' : boderColor;
    }

    if (!this.userCss?.corFonte || this.userCss.corFonte === 'none') {
      return desaturate(0.78, '#fff');
    }

    return selected ? this.userCss.corFonte : this.getColorContrast(this.userCss.corFonte, 0.3);
  }

  changeSelectedProfile(profileIndicator: number) {
    if (!this.userProfile || !this.userCss) {
      return;
    }

    const isDefaultProfile = profileIndicator === -1;

    const templateCallingCard = this.userCss.callingCardImageUrl;

    if (isDefaultProfile) {
      let callingCardImage = this.userProfile.documentUserProfile.uriImageBackground;

      if (this.userProfile.documentUserCSS.locked && templateCallingCard) {
        callingCardImage = templateCallingCard;
      }

      if (!callingCardImage) {
        callingCardImage = this.imagemBackgroundUrl;
      }

      this.selectedProfile = {
        indicator: profileIndicator,
        data: {
          visible: true,
          emailContacts: this.userProfile.documentUserProfile.listContacts.filter(e => e.type == 'email')?.map(e => e.value),
          locations: this.userProfile.documentUserProfile.listContacts.filter(e => e.type == 'localizacao')?.map(e => e.value),
          phoneContacts: this.userProfile.documentUserProfile.listContacts.filter(e => e.type == 'telefone')?.map(e => `${e?.pais?.codigo} ${e?.value}`),
          typeBio: 'default',
          bio: this.userProfile.documentUserProfile.bio,
          header: this.userProfile.documentUserProfile.header,
          listButtons: this.userProfile.documentUserProfile.listButtons.map((button) => ({
            ...button,
            ...(button.image && ({
              image: button.image.replaceAll(' ', '%20')
            })),
            formattedLink: this.transformUrl(button?.link)
          })).slice(0, this.profileLimits.linksDisplay),
          listContacts: this.userProfile.documentUserProfile.listContacts.map((c, index) => ({
            ...c,
            pais: c.pais !== undefined ? this.paises.find(p => p.sigla === c.pais?.sigla || '') : c.pais,
          })).slice(0, this.profileLimits.contactsDisplay),
          listSocialNetwork: this.userProfile.documentUserProfile.listSocialNetwork.slice(0, this.profileLimits.socialLinksDisplay),
          listURI: this.userProfile.documentUserProfile.listURI,
          listUriImages: this.userProfile.documentUserProfile.listUriImages,
          name: this.userProfile.documentUserProfile.name,
          showImageProfile: this.userProfile.documentUserProfile.showImageProfile,
          uriImageBackground: callingCardImage,
          uriImageProfile: this.userProfile.documentUserProfile.uriImageProfile || this.imagemPerfilUrl,
        }
      }

      return;
    }

    const selectedProfileData = this.userProfile.documentUserProfile.listProfile[profileIndicator]

    if (!selectedProfileData) {
      return
    }

    let callingCardImage = selectedProfileData.uriImageBackground;

    if (this.userProfile.documentUserCSS.locked && templateCallingCard) {
      callingCardImage = templateCallingCard;
    }

    if (!callingCardImage) {
      callingCardImage = this.imagemBackgroundUrl;
    }

    this.selectedProfile = {
      data: {
        ...selectedProfileData,
        visible: selectedProfileData.visible,
        emailContacts: selectedProfileData.listContacts.filter(e => e.type == 'email')?.map(e => e.value),
        locations: selectedProfileData.listContacts.filter(e => e.type == 'localizacao')?.map(e => e.value),
        phoneContacts: selectedProfileData.listContacts.filter(e => e.type == 'telefone')?.map(e => `${e?.pais?.codigo} ${e?.value}`),
        listButtons: selectedProfileData.listButtons.map((button) => ({
          ...button,
          ...(button.image && ({
            image: button.image.replaceAll(' ', '%20')
          })),
          formattedLink: this.transformUrl(button?.link)
        })).slice(0, this.profileLimits.linksDisplay),
        listContacts: selectedProfileData.listContacts.map((c) => ({
          ...c,
          pais: c.pais !== undefined ? this.paises.find(p => p.sigla === c.pais?.sigla || '') : c.pais,
        })).slice(0, this.profileLimits.contactsDisplay),
        listSocialNetwork: selectedProfileData.listSocialNetwork.slice(0, this.profileLimits.socialLinksDisplay),
        uriImageBackground: callingCardImage,
        uriImageProfile: selectedProfileData.uriImageProfile || this.imagemPerfilUrl,
      },
      indicator: profileIndicator
    }
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(_event?: any) {
    this.screenWidth = window.innerWidth;
  }

  toggleMenuActions() {
    this.menuActionsPopupOpened = !this.menuActionsPopupOpened;
  }

  getUserByUri(): void {
    const request = new UserUriRequest(this.uri);
    this.perfilUsuarioService.userByUri(request).
      pipe(
        filter((mayBeOk: HttpResponse<IUserProfileWithCSS>) => mayBeOk.ok),
        map((response: HttpResponse<IUserProfileWithCSS>) => response.body))
      .subscribe({
        next: (res) => this.setResponse(res),
        error: (res) => this.onError()
      });
  }

  setResponse(res: IUserProfileWithCSS | null): void {
    if (res && res.documentUserCSS && res.documentUserProfile) {
      this.profiles = [{name: 'default', visible: true}];

      if (res.documentUserProfile.listProfile) {
        this.profiles = [...res.documentUserProfile.listProfile.map(profile => ({name: profile.name, visible: profile.visible}))];
        this.hasSomeUserVisible = res.documentUserProfile.listProfile.some(profile => profile.visible === null || profile.visible);
      }

      this.profileLimits = {
        ...res.documentUser.publicPlan,
        linksDisplay: res.documentUser.publicPlan.linksDisplay === -1 ? 10000 : res.documentUser.publicPlan.linksDisplay,
        contactsDisplay: res.documentUser.publicPlan.contactsDisplay === -1 ? 10000 : res.documentUser.publicPlan.contactsDisplay,
        socialLinksDisplay: res.documentUser.publicPlan.socialLinksDisplay === -1 ? 10000 : res.documentUser.publicPlan.socialLinksDisplay,
      };

      this.userProfile = res;
      this.userCss = res.documentUserCSS ? JSON.parse(res.documentUserCSS.css) : {};
      this.profileExists = true;

      if (res.documentUserProfile.listContacts) {
        res.documentUserProfile.listContacts = res.documentUserProfile.listContacts.slice(0, this.profileLimits.contactsDisplay);

        const firstPhoneContact = res.documentUserProfile.listContacts.find(e => e.type == 'telefone');

        this.principalEmailContact = this.userProfile.documentUserProfile.listContacts.find(e => e.type == 'email')?.value || null;
        this.emailContacts = this.userProfile.documentUserProfile.listContacts.filter(e => e.type == 'email')?.map(e => e.value);
        this.locationContacts = this.userProfile.documentUserProfile.listContacts.filter(e => e.type == 'localizacao')?.map(e => e.value);
        this.phoneContacts = this.userProfile.documentUserProfile.listContacts.filter(e => e.type == 'telefone')?.map(e => `${e?.pais?.codigo} ${e?.value}`);
        this.principalLocationContact = this.userProfile.documentUserProfile.listContacts.find(e => e.type == 'localizacao')?.value || null;
        this.principalPhoneContact = firstPhoneContact ? `${firstPhoneContact?.pais?.codigo}${firstPhoneContact?.value}` : null;
      }

      if (this.userProfile && this.userProfile.documentUserProfile && this.uri === this.userProfile.documentUserProfile.idUser && this.userProfile.documentUserProfile.listURI && this.userProfile.documentUserProfile.listURI[0]) {
        this.urlOriginal = this.urlOriginal.replace(this.uri, this.userProfile.documentUserProfile.listURI[0].link)
        window.history.replaceState({}, '', this.urlOriginal);
      }

      if (!(this.userProfile.documentUserProfile.networkingControl && this.userProfile.documentUserProfile.networkingControl.max && this.userProfile.documentUserProfile.networkingControl.used)) {
        this.userProfile.documentUserProfile.networkingControl = { used: 0, max: 15 };
      }

      this.refreshContactAllowance();

      this.userProfile.documentUserProfile.listSocialNetwork = this.userProfile?.documentUserProfile.listSocialNetwork.slice(0, this.profileLimits.socialLinksDisplay) || [];

      this.changeSelectedProfile(-1);
    } else {
      this.perfilEmConstrucao = true;
      this.userCss = new UserCss();
      this.nome = res && res.documentUserProfile ? res.documentUserProfile.name : '';
      this.biografia = "Meu Sluper está em Construção, em breve você terá acesso as minhas informações.";
      this.profileExists = true;

      if (res && res.documentUserProfile &&
        this.uri === res.documentUserProfile.idUser &&
        res.documentUserProfile.listURI && res.documentUserProfile.listURI[0]) {
        this.urlOriginal = this.urlOriginal.replace(this.uri, res.documentUserProfile.listURI[0].link)
        window.history.replaceState({}, '', this.urlOriginal);
      }
    }
  }

  onError() {
    window.location.href = this.baseUrlProfile + this.uri;
  }

  getBackground(): string | undefined {
    if (!this.userCss) {
      return undefined
    }

    if (this.userCss.corSecundaria) {
      return 'linear-gradient(' + this.userCss.direcaoDegrade + 'deg, ' + this.userCss.corPrimaria + ' 0%, ' + this.userCss.corSecundaria + ' 100%)'
    }

    return this.userCss.corPrimaria;
  }

  getPreenchimentoCabecalho() {
    return this.userCss?.preenchimento;
  }

  getBoxShadowCabecalho() {
  }

  getCorFonteCabecalho() {
    return this.userCss?.corFonte;
  }

  containsSocialNetwork(socialNetwork: string): boolean | undefined {
    if (!this.userProfile) {
      return undefined;
    }

    let isPresent = this.selectedProfile.data.listSocialNetwork.find(e => e.type == socialNetwork);

    return !!isPresent;
  }

  goToUrl(socialNetwork: string) {
    if (!this.userProfile) {
      return;
    }

    let e = this.userProfile.documentUserProfile.listSocialNetwork.find(e => e.type == socialNetwork);

    if (!e?.link) {
      return;
    }

    window.open(e?.link, "_blank");

    this.perfilUsuarioService.createMetric({
      idUser: this.userProfile?.documentUserProfile.idUser,
      infoGroup: 'SocialNetwork',
      value: e?.link
    }).subscribe();
  }

  getBackgroundPerfil() {
    return "url(" + this.selectedProfile.data.uriImageBackground + ")";
  }

  compartilhar() {
    if (!navigator.share) {
      return;
    }

    const shareData = {
      title: 'Sluper',
      text: 'Sluper do ' + this.nome,
      url: window.location.href
    };

    navigator.share(shareData)
      .then(() => {
        console.log('Conteúdo compartilhado com sucesso!');
      })
      .catch((error) => {
        console.error('Erro ao compartilhar:', error);
      });
  }

  openQRCodeModal() {
    const modalRef = this.modalService.open(QRCodeModalComponent, { centered: true, size: "sm" });
    modalRef.componentInstance.url = window.location.href;
  }

  gerarVcard() {
    if (!this.userProfile?.documentUserProfile) {
      return;
    }

    this.perfilUsuarioService.getImageProfile(this.userProfile?.documentUserProfile.idUser).
      pipe(
        filter((mayBeOk: HttpResponse<IImageResponse>) => mayBeOk.ok),
        map((response: HttpResponse<IImageResponse>) => response.body))
      .subscribe({
        next: (res) => {
          if (res && res.base64Image) {
            this.base64Image = res?.base64Image;
          }
          this.montaVCard();
        },
        error: (res) => {
          this.montaVCard();
        }
      });
  }

  montaVCard() {
    let vcardContent = `BEGIN:VCARD
VERSION:3.0
FN:${this.userProfile?.documentUserProfile.name}
URL;CHARSET=UTF-8:${window.location.href}`;

    if (this.base64Image && this.base64Image != '') {
      vcardContent += `\nPHOTO;TYPE=JPEG;ENCODING=b:${this.base64Image}`;
    }

    const contactTypePrefixes = {
      'telefone': 'TEL;TYPE=WORK,VOICE:',
      'email': 'EMAIL;CHARSET=UTF-8;type=HOME,INTERNET:',
      'localizacao': 'ADR;CHARSET=UTF-8;TYPE=HOME:'
    }

    if (this.userProfile?.documentUserProfile.listContacts) {
      this.userProfile?.documentUserProfile.listContacts.forEach((contact) => {
          vcardContent += `\n${contactTypePrefixes[contact.type as 'telefone' | 'email' | 'localizacao']}${contact.value}`;
      });
    }

    if (this.userProfile?.documentUserProfile.listSocialNetwork) {
      this.userProfile?.documentUserProfile.listSocialNetwork.forEach((social) => {
        if (social.link) {
          vcardContent += `\nURL;CHARSET=UTF-8:${social.link}`;
        }
      });
    }

    vcardContent += '\nEND:VCARD';

    const blob = new Blob([vcardContent], { type: 'text/vcard' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', 'contatoSluper.vcf');
    link.click();
    window.URL.revokeObjectURL(url);

    if (this.userProfile?.documentUserProfile.idUser) {
      this.perfilUsuarioService.createMetric({
        idUser: this.userProfile?.documentUserProfile.idUser,
        infoGroup: 'VCard',
        value: url
      }).subscribe();
    }
  }

  getHome() {
    window.location.href = this.baseUrlProfile
  }

  getUserSaleLink() {
    const formattedId = this.userProfile?.documentUserProfile.idUser?.replaceAll('-', '_');

    return `https://sluper.digital/?partner=${formattedId}-${this.userProfile?.documentUser.promoCode}`;
  }

  getLink(link: string) {
    if (link?.includes("http://") || link?.includes("https://")) {
      return link;
    } else {
      return "https://" + link;
    }
  }

  saveLinkMetrics(link: string) {
    if (this.userProfile?.documentUserCSS) {
      this.perfilUsuarioService.createMetric({
        idUser: this.userProfile?.documentUserProfile.idUser,
        infoGroup: 'Link',
        value: link
      }).subscribe();
    }
  }

  setConditionallyRequiredValidator(event: Event) {
    const inputEvent = event as any;

    if (inputEvent.target?.id === 'email') {
      if (inputEvent.target?.value.length >= 1) {
        this.sendContactsForm.controls['phone'].clearValidators();
        this.sendContactsForm.controls['phone'].setErrors(null);
      } else {
        this.sendContactsForm.controls.phone.setValidators([Validators.required]);
      }
    }

    if (inputEvent.target?.id === 'phone') {
      if (inputEvent.target?.value.length >= 1) {
        this.sendContactsForm.controls['email'].clearValidators();
        this.sendContactsForm.controls['email'].setValidators([Validators.email]);
        this.sendContactsForm.controls['email'].setErrors(null);
      } else {
        this.sendContactsForm.controls.email.setValidators([Validators.required, Validators.email]);
      }
    }
  }

  handlePhoneCountryInput(event: Event) {
    const inputEvent = event as any;

    if (!inputEvent.target.value) {
      this.sendContactsForm.controls.phone.disable();
    } else {
      this.sendContactsForm.controls.phone.enable();
    }
  }

  sendContacts() {
    if (this.sendContactsForm && this.sendContactsForm.status === 'INVALID') {

      if (this.sendContactsForm?.controls['email']?.errors?.['email']) {
        this.sendContactFormEmailError = true;
      }

      return;
    }

    this.sendContactFormEmailError = false;

    this.perfilUsuarioService.sendContacts({
      idUser: this.userProfile?.documentUserProfile.idUser || '',
      contactName: this.sendContactsForm.value.name as string,
      ...(this.sendContactsForm.value.email && this.sendContactsForm.value.email.length > 0 && { contactEmail: this.sendContactsForm.value.email }),
      ...(this.sendContactsForm.value.phone && this.sendContactsForm.value.phone.length > 0 && { contactPhone: `${this.sendContactsForm.value.countryPhone?.codigo} ${this.sendContactsForm.value.phone}` }),
      ...(this.sendContactsForm.value.company && this.sendContactsForm.value.company.length > 0 && { contactCompany: this.sendContactsForm.value.company as string }),
      ...(this.sendContactsForm.value.position && this.sendContactsForm.value.position.length > 0 && { contactPosition: this.sendContactsForm.value.position as string }),
    }).subscribe(response => {
      if (response.status === 204) {
        this.toastr.success('Contato enviado com sucesso!', `Contato enviado para ${this.userProfile?.documentUserProfile.name}!`);

        if (this.userProfile) {
          this.getUserByUri();
        }

        this.refreshContactAllowance();
      } else {
        this.toastr.error('Erro ao enviar contato!');
      }

        this.modalComponent?.toggle();
        this.sendContactsForm.reset();
    });
  }

  refreshContactAllowance() {
     if (this.userProfile && this.userProfile.documentUserProfile.networkingControl.max === -1) {
       this.addContact = true;

       return;
    }

    this.addContact = this.userProfile && this.userProfile.documentUserProfile.networkingControl.used < this.userProfile.documentUserProfile.networkingControl.max || false;
  }

  closeCangeContactsButton() {
    this.changeContactsButtonOpened = false;
  }

  openShowMoreEmailsPopup() {
    if (this.emailContacts.length < 1) {
      return;
    }

    this.showMoreEmailsPopupOpened = true;
  }

  openShowMorePhonesPopup() {
    if (this.phoneContacts.length < 1) {
      return;
    }

    this.showMorePhonesPopupOpened = true;
  }

  openShowMoreLocationsPopup() {
    if (this.locationContacts.length < 1) {
      return;
    }

    this.showMoreLocationsPopupOpened = true;
  }

  closeShowMoreInfosPopups() {
    this.showMorePhonesPopupOpened = false;
    this.showMoreEmailsPopupOpened = false;
    this.showMoreLocationsPopupOpened = false;
    this.menuActionsPopupOpened = false;
    this.textCopied = '';
  }

  copyPhoneToClipboard(value: string) {
    navigator.clipboard.writeText(value);

    this.textCopied = value;
  }

  callToPhone(value: string) {
    window.open(`tel:${value}`);
  }
}
