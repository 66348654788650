export interface Pais {
  gentilico: string;
  nomePais: string;
  nomePaisInt: string;
  sigla: string;
  codigo?: string;
  mascara: string;
}

export const LISTA_PAIS: Pais[] = [{
  gentilico: "afegãne",
  nomePais: "Afeganistão",
  nomePaisInt: "Afghanistan",
  sigla: "AF",
  codigo: "+93",
  mascara: " 00 000 0000"
},
{
  gentilico: "sul africana",
  nomePais: "África do Sul",
  nomePaisInt: "South Africa",
  sigla: "ZA",
  codigo: "+27",
  mascara: " 00 000 0000"
},
{
  gentilico: "albanesa",
  nomePais: "Albânia",
  nomePaisInt: "Albania",
  sigla: "AL",
  codigo: "+355",
  mascara: "(000)000 000"
},
{
  gentilico: "alemã",
  nomePais: "Alemanha",
  nomePaisInt: "Germany",
  sigla: "DE",
  codigo: "+49",
  mascara: " 000 000"
},
{
  gentilico: "andorrana",
  nomePais: "Andorra",
  nomePaisInt: "Andorra",
  sigla: "AD",
  codigo: "+376",
  mascara: " 000 000"
},
{
  gentilico: "angolana",
  nomePais: "Angola",
  nomePaisInt: "Angola",
  sigla: "AO",
  codigo: "+244",
  mascara: "(000)000 000"
},
{
  gentilico: "anguillana",
  nomePais: "Anguilla",
  nomePaisInt: "Anguilla",
  sigla: "AI",
  codigo: "+1(264)",
  mascara: "000 0000"
},
{
  gentilico: "de antártida",
  nomePais: "Antártida",
  nomePaisInt: "Antarctica",
  sigla: "AQ",
  codigo: "+672 1",
  mascara: "00 000"
},
{
  gentilico: "antiguana",
  nomePais: "Antígua e Barbuda",
  nomePaisInt: "Antigua & Barbuda",
  sigla: "AG",
  codigo: "+1(268)",
  mascara: "000 0000"
},
{
  gentilico: "saudita",
  nomePais: "Arábia Saudita",
  nomePaisInt: "Saudi Arabia",
  sigla: "SA",
  codigo: "+966",
  mascara: " 0 000 0000"
},
{
  gentilico: "argelina",
  nomePais: "Argélia",
  nomePaisInt: "Algeria",
  sigla: "DZ",
  codigo: "+213",
  mascara: " 00 000 0000"
},
{
  gentilico: "argentina",
  nomePais: "Argentina",
  nomePaisInt: "Argentina",
  sigla: "AR",
  codigo: "+54",
  mascara: "(000)000 0000"
},
{
  gentilico: "armênia",
  nomePais: "Armênia",
  nomePaisInt: "Armenia",
  sigla: "AM",
  codigo: "+374",
  mascara: " 00 000 000"
},
{
  gentilico: "arubana",
  nomePais: "Aruba",
  nomePaisInt: "Aruba",
  sigla: "AW",
  codigo: "+297",
  mascara: " 000 0000"
},
{
  gentilico: "australiana",
  nomePais: "Austrália",
  nomePaisInt: "Australia",
  sigla: "AU",
  codigo: "+61",
  mascara: " 0 0000 0000"
},
{
  gentilico: "austríaca",
  nomePais: "Áustria",
  nomePaisInt: "Austria",
  sigla: "AT",
  codigo: "+43",
  mascara: "(000)000 0000"
},
{
  gentilico: "azerbaijano",
  nomePais: "Azerbaijão",
  nomePaisInt: "Azerbaijan",
  sigla: "AZ",
  codigo: "+994",
  mascara: " 00 000 00 00"
},
{
  gentilico: "bahamense",
  nomePais: "Bahamas",
  nomePaisInt: "Bahamas",
  sigla: "BS",
  codigo: "+1(242)",
  mascara: "000 0000"
},
{
  gentilico: "barenita",
  nomePais: "Bahrein",
  nomePaisInt: "Bahrain",
  sigla: "BH",
  codigo: "+973",
  mascara: " 0000 0000"
},
{
  gentilico: "bengali",
  nomePais: "Bangladesh",
  nomePaisInt: "Bangladesh",
  sigla: "BD",
  codigo: "+880",
  mascara: " 00 000 000"
},
{
  gentilico: "barbadiana",
  nomePais: "Barbados",
  nomePaisInt: "Barbados",
  sigla: "BB",
  codigo: "+1(246)",
  mascara: "000 0000"
},
{
  gentilico: "bielo russa",
  nomePais: "Belarus",
  nomePaisInt: "Belarus",
  sigla: "BY",
  codigo: "+375",
  mascara: "(00) 000 00 00"
},
{
  gentilico: "belga",
  nomePais: "Bélgica",
  nomePaisInt: "Belgium",
  sigla: "BE",
  codigo: "+32",
  mascara: "(000)000 000"
},
{
  gentilico: "belizenha",
  nomePais: "Belize",
  nomePaisInt: "Belize",
  sigla: "BZ",
  codigo: "+501",
  mascara: " 000 0000"
},
{
  gentilico: "beninense",
  nomePais: "Benin",
  nomePaisInt: "Benin",
  sigla: "BJ",
  codigo: "+229",
  mascara: " 00 00 0000"
},
{
  gentilico: "bermudiana",
  nomePais: "Bermudas",
  nomePaisInt: "Bermuda",
  sigla: "BM",
  codigo: "+1(441)",
  mascara: "000 0000"
},
{
  gentilico: "boliviana",
  nomePais: "Bolívia",
  nomePaisInt: "Bolivia",
  sigla: "BO",
  codigo: "+591",
  mascara: " 0 000 0000"
},
{
  gentilico: "bósnia",
  nomePais: "Bósnia-Herzegóvina",
  nomePaisInt: "Bosnia & Herzegovina",
  sigla: "BA",
  codigo: "+387",
  mascara: " 00 0000"
},
{
  gentilico: "betchuana",
  nomePais: "Botsuana",
  nomePaisInt: "Botswana",
  sigla: "BW",
  codigo: "+267",
  mascara: " 00 000 000"
},
{
  gentilico: "brasileira",
  nomePais: "Brasil",
  nomePaisInt: "Brazil",
  sigla: "BR",
  codigo: "+55",
  mascara: "(00) 00000 0000"
},
{
  gentilico: "bruneiana",
  nomePais: "Brunei",
  nomePaisInt: "Brunei",
  sigla: "BN",
  codigo: "+673",
  mascara: " 000 0000"
},
{
  gentilico: "búlgara",
  nomePais: "Bulgária",
  nomePaisInt: "Bulgaria",
  sigla: "BG",
  codigo: "+359",
  mascara: "(000)000 000"
},
{
  gentilico: "burquinês",
  nomePais: "Burkina Fasso",
  nomePaisInt: "Burkina Faso",
  sigla: "BF",
  codigo: "+226",
  mascara: " 00 00 0000"
},
{
  gentilico: "burundinesa",
  nomePais: "Burundi",
  nomePaisInt: "Burundi",
  sigla: "BI",
  codigo: "+257",
  mascara: " 00 00 0000"
},
{
  gentilico: "butanesa",
  nomePais: "Butão",
  nomePaisInt: "Bhutan",
  sigla: "BT",
  codigo: "+975",
  mascara: " 0 000 000"
},
{
  gentilico: "cabo verdiana",
  nomePais: "Cabo Verde",
  nomePaisInt: "Cape Verde",
  sigla: "CV",
  codigo: "+238",
  mascara: "(000) 00 00"
},
{
  gentilico: "camaronesa",
  nomePais: "Camarões",
  nomePaisInt: "Cameroon",
  sigla: "CM",
  codigo: "+237",
  mascara: " 0000 0000"
},
{
  gentilico: "cambojana",
  nomePais: "Camboja",
  nomePaisInt: "Cambodia",
  sigla: "KH",
  codigo: "+855",
  mascara: " 00 000 000"
},
{
  gentilico: "canadense",
  nomePais: "Canadá",
  nomePaisInt: "Canada",
  sigla: "CA",
  codigo: "+1",
  mascara: "(000) 000 0000"
},
{
  gentilico: "cazaque",
  nomePais: "Cazaquistão",
  nomePaisInt: "Kazakhstan",
  sigla: "KZ",
  codigo: "+7",
  mascara: "(000) 000 00 00"
},
{
  gentilico: "chadiana",
  nomePais: "Chade",
  nomePaisInt: "Chad",
  sigla: "TD",
  codigo: "+235",
  mascara: " 00 00 00 00"
},
{
  gentilico: "chilena",
  nomePais: "Chile",
  nomePaisInt: "Chile",
  sigla: "CL",
  codigo: "+56",
  mascara: " 0 0000 0000"
},
{
  gentilico: "chinesa",
  nomePais: "China",
  nomePaisInt: "China",
  sigla: "CN",
  codigo: "+86",
  mascara: " 00 00000 00000"
},
{
  gentilico: "cipriota",
  nomePais: "Chipre",
  nomePaisInt: "Cyprus",
  sigla: "CY",
  codigo: "+357",
  mascara: " 00 000 000"
},
{
  gentilico: "cingapuriana",
  nomePais: "Cingapura",
  nomePaisInt: "Singapore",
  sigla: "SG",
  codigo: "+65",
  mascara: " 0000 0000"
},
{
  gentilico: "colombiana",
  nomePais: "Colômbia",
  nomePaisInt: "Colombia",
  sigla: "CO",
  codigo: "+57",
  mascara: "(000) 000 0000"
},
{
  gentilico: "comorense",
  nomePais: "Comores",
  nomePaisInt: "Comoros",
  sigla: "KM",
  codigo: "+269",
  mascara: " 00 00000"
},
{
  gentilico: "congolesa",
  nomePais: "Congo",
  nomePaisInt: "Congo (Republic)",
  sigla: "CG",
  codigo: "+242",
  mascara: " 00 000 0000"
},
{
  gentilico: "norte coreano",
  nomePais: "Coréia do Norte",
  nomePaisInt: "North Korea",
  sigla: "KP",
  codigo: "+850",
  mascara: " 0000 0000000000000"
},
{
  gentilico: "norte coreana",
  nomePais: "Coréia do Sul",
  nomePaisInt: "South Korea",
  sigla: "KR",
  codigo: "+82",
  mascara: " 00 000 0000"
},
{
  gentilico: "marfinense",
  nomePais: "Costa do Marfim",
  nomePaisInt: "Côte d¿Ivoire",
  sigla: "CI",
  codigo: "+225",
  mascara: " 00 000 000"
},
{
  gentilico: "costarriquenha",
  nomePais: "Costa Rica",
  nomePaisInt: "Costa Rica",
  sigla: "CR",
  codigo: "+506",
  mascara: " 0000 0000"
},
{
  gentilico: "croata",
  nomePais: "Croácia",
  nomePaisInt: "Croatia",
  sigla: "HR",
  codigo: "+385",
  mascara: " 00 000 000"
},
{
  gentilico: "cubana",
  nomePais: "Cuba",
  nomePaisInt: "Cuba",
  sigla: "CU",
  codigo: "+53",
  mascara: " 0 000 0000"
},
{
  gentilico: "curaçauense",
  nomePais: "Curaçao",
  nomePaisInt: "Curaçao",
  sigla: "CW",
  codigo: "+599",
  mascara: " 000 0000"
},
{
  gentilico: "dinamarquesa",
  nomePais: "Dinamarca",
  nomePaisInt: "Denmark",
  sigla: "DK",
  codigo: "+45",
  mascara: " 00 00 00 00"
},
{
  gentilico: "djibutiana",
  nomePais: "Djibuti",
  nomePaisInt: "Djibouti",
  sigla: "DJ",
  codigo: "+253",
  mascara: " 00 00 00 00"
},
{
  gentilico: "dominiquense",
  nomePais: "Dominica",
  nomePaisInt: "Dominica",
  sigla: "DM",
  codigo: "+1(767)",
  mascara: "000 0000"
},
{
  gentilico: "egípcia",
  nomePais: "Egito",
  nomePaisInt: "Egypt",
  sigla: "EG",
  codigo: "+20",
  mascara: "(000) 000 0000"
},
{
  gentilico: "salvadorenha",
  nomePais: "El Salvador",
  nomePaisInt: "El Salvador",
  sigla: "SV",
  codigo: "+503",
  mascara: " 00 00 0000"
},
{
  gentilico: "árabe",
  nomePais: "Emirados Árabes",
  nomePaisInt: "United Arab Emirates",
  sigla: "AE",
  codigo: "+971",
  mascara: " 0 000 0000"
},
{
  gentilico: "equatoriana",
  nomePais: "Equador",
  nomePaisInt: "Ecuador",
  sigla: "EC",
  codigo: "+593",
  mascara: " 0 000 0000"
},
{
  gentilico: "eritreia",
  nomePais: "Eritréia",
  nomePaisInt: "Eritrea",
  sigla: "ER",
  codigo: "+291",
  mascara: " 0 000 000"
},
{
  gentilico: "eslovaco",
  nomePais: "Eslováquia",
  nomePaisInt: "Slovakia",
  sigla: "SK",
  codigo: "+421",
  mascara: "(000) 000 000"
},
{
  gentilico: "esloveno",
  nomePais: "Eslovênia",
  nomePaisInt: "Slovenia",
  sigla: "SI",
  codigo: "+386",
  mascara: " 00 000 000"
},
{
  gentilico: "espanhola",
  nomePais: "Espanha",
  nomePaisInt: "Spain",
  sigla: "ES",
  codigo: "+34",
  mascara: "(000) 000 000"
},
{
  gentilico: "norte americana",
  nomePais: "Estados Unidos",
  nomePaisInt: "United States",
  sigla: "US",
  codigo: "+1",
  mascara: "(000) 000 0000"
},
{
  gentilico: "estoniana",
  nomePais: "Estônia",
  nomePaisInt: "Estonia",
  sigla: "EE",
  codigo: "+372",
  mascara: " 000 0000"
},
{
  gentilico: "etíope",
  nomePais: "Etiópia",
  nomePaisInt: "Ethiopia",
  sigla: "ET",
  codigo: "+251",
  mascara: " 00 000 0000"
},
{
  gentilico: "fijiana",
  nomePais: "Fiji",
  nomePaisInt: "Fiji",
  sigla: "FJ",
  codigo: "+679",
  mascara: " 00 00000"
},
{
  gentilico: "filipina",
  nomePais: "Filipinas",
  nomePaisInt: "Philippines",
  sigla: "PH",
  codigo: "+63",
  mascara: "(000) 000 0000"
},
{
  gentilico: "finlandesa",
  nomePais: "Finlândia",
  nomePaisInt: "Finland",
  sigla: "FI",
  codigo: "+358",
  mascara: "(000) 000 00 00"
},
{
  gentilico: "francesa",
  nomePais: "França",
  nomePaisInt: "France",
  sigla: "FR",
  codigo: "+590",
  mascara: "(000) 000 000"
},
{
  gentilico: "gabonesa",
  nomePais: "Gabão",
  nomePaisInt: "Gabon",
  sigla: "GA",
  codigo: "+241",
  mascara: " 0 00 00 00"
},
{
  gentilico: "gambiana",
  nomePais: "Gâmbia",
  nomePaisInt: "Gambia",
  sigla: "GM",
  codigo: "+220",
  mascara: "(000)00 00"
},
{
  gentilico: "ganense",
  nomePais: "Gana",
  nomePaisInt: "Ghana",
  sigla: "GH",
  codigo: "+233",
  mascara: "(000) 000 000"
},
{
  gentilico: "georgiana",
  nomePais: "Geórgia",
  nomePaisInt: "Georgia",
  sigla: "GE",
  codigo: "+995",
  mascara: "(000) 000 000"
},
{
  gentilico: "gibraltariana",
  nomePais: "Gibraltar",
  nomePaisInt: "Gibraltar",
  sigla: "GI",
  codigo: "+350",
  mascara: " 000 00000"
},
{
  gentilico: "inglesa",
  nomePais: "Grã-Bretanha",
  nomePaisInt: "United Kingdom",
  sigla: "UK",
  codigo: "+44",
  mascara: " 00 0000 0000"
},
{
  gentilico: "granadina",
  nomePais: "Granada",
  nomePaisInt: "Grenada",
  sigla: "GD",
  codigo: "+1(473)",
  mascara: "000 0000"
},
{
  gentilico: "grega",
  nomePais: "Grécia",
  nomePaisInt: "Greece",
  sigla: "GR",
  codigo: "+30",
  mascara: "(000) 000 0000"
},
{
  gentilico: "groenlandesa",
  nomePais: "Groelândia",
  nomePaisInt: "Greenland",
  sigla: "GL",
  codigo: "+299",
  mascara: " 00 00 00"
},
{
  gentilico: "guadalupense",
  nomePais: "Guadalupe",
  nomePaisInt: "Guadeloupe",
  sigla: "GP",
  mascara: " 00 00 00"
},
{
  gentilico: "guamês",
  nomePais: "Guam",
  nomePaisInt: "Guam",
  sigla: "GU",
  codigo: "+1(671)",
  mascara: "000 0000"
},
{
  gentilico: "guatemalteca",
  nomePais: "Guatemala",
  nomePaisInt: "Guatemala",
  sigla: "GT",
  codigo: "+502",
  mascara: " 0 000 0000"
},
{
  gentilico: "guernesiano",
  nomePais: "Guernsey",
  nomePaisInt: "Guernsey",
  sigla: "GG",
  mascara: " 00 00 00"
},
{
  gentilico: "guianense",
  nomePais: "Guiana",
  nomePaisInt: "Guyana",
  sigla: "GY",
  codigo: "+592",
  mascara: " 000 0000"
},
{
  gentilico: "franco guianense",
  nomePais: "Guiana Francesa",
  nomePaisInt: "French Guiana",
  sigla: "GF",
  codigo: "+594",
  mascara: " 00000 0000"
},
{
  gentilico: "guinéu equatoriano",
  nomePais: "Guiné",
  nomePaisInt: "Guinea",
  sigla: "GN",
  codigo: "+224",
  mascara: " 00 000 000"
},
{
  gentilico: "guinéu equatoriano",
  nomePais: "Guiné Equatorial",
  nomePaisInt: "Equatorial Guinea",
  sigla: "GQ",
  codigo: "+240",
  mascara: " 00 000 0000"
},
{
  gentilico: "guineense",
  nomePais: "Guiné-Bissau",
  nomePaisInt: "Guinea Bissau",
  sigla: "GW",
  codigo: "+245",
  mascara: " 0 000000"
},
{
  gentilico: "haitiana",
  nomePais: "Haiti",
  nomePaisInt: "Haiti",
  sigla: "HT",
  codigo: "+509",
  mascara: " 00 00 0000"
},
{
  gentilico: "holandês",
  nomePais: "Holanda",
  nomePaisInt: "Netherlands",
  sigla: "NL",
  codigo: "+31",
  mascara: " 00 000 0000"
},
{
  gentilico: "hondurenha",
  nomePais: "Honduras",
  nomePaisInt: "Honduras",
  sigla: "HN",
  codigo: "+504",
  mascara: " 0000 0000"
},
{
  gentilico: "hong konguiana ou chinesa",
  nomePais: "Hong Kong",
  nomePaisInt: "Hong Kong",
  sigla: "HK",
  codigo: "+852",
  mascara: " 0000 0000"
},
{
  gentilico: "húngara",
  nomePais: "Hungria",
  nomePaisInt: "Hungary",
  sigla: "HU",
  codigo: "+36",
  mascara: "(000) 000 000"
},
{
  gentilico: "iemenita",
  nomePais: "Iêmen",
  nomePaisInt: "Yemen",
  sigla: "YE",
  codigo: "+967",
  mascara: " 00 000 000"
},
{
  gentilico: "da ilha bouvet",
  nomePais: "Ilha Bouvet",
  nomePaisInt: "Bouvet Island",
  sigla: "BV",
  mascara: " 00 00 00"
},
{
  gentilico: "da ilha de ascensão",
  nomePais: "Ilha de Ascensão",
  nomePaisInt: "Ascension Island",
  sigla: "AC",
  codigo: "+247",
  mascara: " 0000"
},
{
  gentilico: "reunionense",
  nomePais: "Ilha Reunião",
  nomePaisInt: "Réunion",
  sigla: "RE",
  codigo: "+262",
  mascara: " 00000 0000"
},
{
  gentilico: "caimanês",
  nomePais: "Ilhas Cayman",
  nomePaisInt: "Cayman Islands",
  sigla: "KY",
  codigo: "+1(345)",
  mascara: "000 0000"
},
{
  gentilico: "coquense",
  nomePais: "Ilhas Cocos",
  nomePaisInt: "Cocos (Keeling) Islands",
  sigla: "CC",
  mascara: " 00 00 00"
},
{
  gentilico: "cookense",
  nomePais: "Ilhas Cook",
  nomePaisInt: "Cook Islands",
  sigla: "CK",
  codigo: "+682",
  mascara: " 00 000"
},
{
  gentilico: "faroense",
  nomePais: "Ilhas Faroes",
  nomePaisInt: "Faroe Islands",
  sigla: "FO",
  codigo: "+298",
  mascara: " 000 000"
},
{
  gentilico: "malvinense",
  nomePais: "Ilhas Malvinas",
  nomePaisInt: "Falkland Islands (Islas Malvinas)",
  sigla: "FK",
  codigo: "+500",
  mascara: " 00000"
},
{
  gentilico: "norte marianense",
  nomePais: "Ilhas Marianas do Norte",
  nomePaisInt: "Northern Mariana Islands",
  sigla: "MP",
  codigo: "+1(670)",
  mascara: "000 0000"
},
{
  gentilico: "marshallino",
  nomePais: "Ilhas Marshall",
  nomePaisInt: "Marshall Islands",
  sigla: "MH",
  codigo: "+692",
  mascara: " 000 0000"
},
{
  gentilico: "norfolquino",
  nomePais: "Ilhas Norfolk",
  nomePaisInt: "Norfolk Island",
  sigla: "NF",
  codigo: "+672",
  mascara: " 000 000"
},
{
  gentilico: "salomônico",
  nomePais: "Ilhas Salomão",
  nomePaisInt: "Solomon Islands",
  sigla: "SB",
  codigo: "+677",
  mascara: " 00000"
},
{
  gentilico: "seichelense",
  nomePais: "Ilhas Seychelles",
  nomePaisInt: "Seychelles",
  sigla: "SC",
  codigo: "+248",
  mascara: " 0 000 000"
},
{
  gentilico: "toquelauano",
  nomePais: "Ilhas Tokelau",
  nomePaisInt: "Tokelau",
  sigla: "TK",
  codigo: "+690",
  mascara: " 0000"
},
{
  gentilico: "turquês",
  nomePais: "Ilhas Turks e Caicos",
  nomePaisInt: "Turks & Caicos Islands",
  sigla: "TC",
  codigo: "+1(649)",
  mascara: "000 0000"
},
{
  gentilico: "virginense",
  nomePais: "Ilhas Virgens (EUA)",
  nomePaisInt: "U.S. Virgin Islands",
  sigla: "VI",
  codigo: "+1(340)",
  mascara: "000 0000"
},
{
  gentilico: "virginense",
  nomePais: "Ilhas Virgens (ENG)",
  nomePaisInt: "British Virgin Islands",
  sigla: "VG",
  codigo: "+1(284)",
  mascara: "000 0000"
},
{
  gentilico: "indiano",
  nomePais: "Índia",
  nomePaisInt: "India",
  sigla: "IN",
  codigo: "+91",
  mascara: "(0000) 000 000"
},
{
  gentilico: "indonésia",
  nomePais: "Indonésia",
  nomePaisInt: "Indonesia",
  sigla: "ID",
  codigo: "+62",
  mascara: "(000) 000 00 000"
},
{
  gentilico: "iraniano",
  nomePais: "Irã",
  nomePaisInt: "Iran",
  sigla: "IR",
  codigo: "+98",
  mascara: "(000) 000 0000"
},
{
  gentilico: "iraquiana",
  nomePais: "Iraque",
  nomePaisInt: "Iraq",
  sigla: "IQ",
  codigo: "+964",
  mascara: "(000) 000 0000"
},
{
  gentilico: "irlandesa",
  nomePais: "Irlanda",
  nomePaisInt: "Ireland",
  sigla: "IE",
  codigo: "+353",
  mascara: "(000) 000 000"
},
{
  gentilico: "islandesa",
  nomePais: "Islândia",
  nomePaisInt: "Iceland",
  sigla: "IS",
  codigo: "+354",
  mascara: " 000 0000"
},
{
  gentilico: "israelense",
  nomePais: "Israel",
  nomePaisInt: "Israel",
  sigla: "IL",
  codigo: "+972",
  mascara: " 0 000 0000"
},
{
  gentilico: "italiana",
  nomePais: "Itália",
  nomePaisInt: "Italy",
  sigla: "IT",
  codigo: "+39",
  mascara: "(000) 0000 000"
},
{
  gentilico: "jamaicana",
  nomePais: "Jamaica",
  nomePaisInt: "Jamaica",
  sigla: "JM",
  codigo: "+1(876)",
  mascara: "000 0000"
},
{
  gentilico: "japonesa",
  nomePais: "Japão",
  nomePaisInt: "Japan",
  sigla: "JP",
  codigo: "+81",
  mascara: "(000) 000 000"
},
{
  gentilico: "canalina",
  nomePais: "Jersey",
  nomePaisInt: "Jersey",
  sigla: "JE",
  mascara: " 00 00 00"
},
{
  gentilico: "jordaniana",
  nomePais: "Jordânia",
  nomePaisInt: "Jordan",
  sigla: "JO",
  codigo: "+962",
  mascara: " 0 0000 0000"
},
{
  gentilico: "kiribatiana",
  nomePais: "Kiribati",
  nomePaisInt: "Kiribati",
  sigla: "KI",
  codigo: "+686",
  mascara: " 00 000"
},
{
  gentilico: "kuwaitiano",
  nomePais: "Kuait",
  nomePaisInt: "Kuwait",
  sigla: "KW",
  codigo: "+965",
  mascara: " 0000 0000"
},
{
  gentilico: "laosiana",
  nomePais: "Laos",
  nomePaisInt: "Laos",
  sigla: "LA",
  codigo: "+856",
  mascara: " 00 000 000"
},
{
  gentilico: "lesota",
  nomePais: "Lesoto",
  nomePaisInt: "Lesotho",
  sigla: "LS",
  codigo: "+266",
  mascara: " 0 000 0000"
},
{
  gentilico: "letão",
  nomePais: "Letônia",
  nomePaisInt: "Latvia",
  sigla: "LV",
  codigo: "+371",
  mascara: " 00 000 000"
},
{
  gentilico: "libanesa",
  nomePais: "Líbano",
  nomePaisInt: "Lebanon",
  sigla: "LB",
  codigo: "+961",
  mascara: " 0 000 000"
},
{
  gentilico: "liberiana",
  nomePais: "Libéria",
  nomePaisInt: "Liberia",
  sigla: "LR",
  codigo: "+231",
  mascara: " 00 000 000"
},
{
  gentilico: "líbia",
  nomePais: "Líbia",
  nomePaisInt: "Libya",
  sigla: "LY",
  codigo: "+218",
  mascara: " 00 000 0000"
},
{
  gentilico: "liechtensteiniense",
  nomePais: "Liechtenstein",
  nomePaisInt: "Liechtenstein",
  sigla: "LI",
  codigo: "+423",
  mascara: "(000) 000 0000"
},
{
  gentilico: "lituana",
  nomePais: "Lituânia",
  nomePaisInt: "Lithuania",
  sigla: "LT",
  codigo: "+370",
  mascara: "(000) 00 000"
},
{
  gentilico: "luxemburguesa",
  nomePais: "Luxemburgo",
  nomePaisInt: "Luxembourg",
  sigla: "LU",
  codigo: "+352",
  mascara: "(000) 000 000"
},
{
  gentilico: "macauense",
  nomePais: "Macau",
  nomePaisInt: "Macau",
  sigla: "MO",
  codigo: "+853",
  mascara: " 0000 0000"
},
{
  gentilico: "macedônio",
  nomePais: "Macedônia",
  nomePaisInt: "Macedonia (FYROM)",
  sigla: "MK",
  codigo: "+389",
  mascara: " 00 000 000"
},
{
  gentilico: "malgaxe",
  nomePais: "Madagascar",
  nomePaisInt: "Madagascar",
  sigla: "MG",
  codigo: "+261",
  mascara: " 00 00 00000"
},
{
  gentilico: "malaia",
  nomePais: "Malásia",
  nomePaisInt: "Malaysia",
  sigla: "MY",
  codigo: "+60",
  mascara: " 0 000 000"
},
{
  gentilico: "malauiano",
  nomePais: "Malaui",
  nomePaisInt: "Malawi",
  sigla: "MW",
  codigo: "+265",
  mascara: " 0 0000 0000"
},
{
  gentilico: "maldívia",
  nomePais: "Maldivas",
  nomePaisInt: "Maldives",
  sigla: "MV",
  codigo: "+960",
  mascara: " 000 0000"
},
{
  gentilico: "malinesa",
  nomePais: "Mali",
  nomePaisInt: "Mali",
  sigla: "ML",
  codigo: "+223",
  mascara: " 00 00 0000"
},
{
  gentilico: "maltesa",
  nomePais: "Malta",
  nomePaisInt: "Malta",
  sigla: "MT",
  codigo: "+356",
  mascara: " 0000 0000"
},
{
  gentilico: "marroquina",
  nomePais: "Marrocos",
  nomePaisInt: "Morocco",
  sigla: "MA",
  codigo: "+212",
  mascara: " 00 0000 000"
},
{
  gentilico: "martiniquense",
  nomePais: "Martinica",
  nomePaisInt: "Martinique",
  sigla: "MQ",
  codigo: "+596",
  mascara: "(000) 00 00 00"
},
{
  gentilico: "mauriciana",
  nomePais: "Maurício",
  nomePaisInt: "Mauritius",
  sigla: "MU",
  codigo: "+230",
  mascara: " 000 0000"
},
{
  gentilico: "mauritana",
  nomePais: "Mauritânia",
  nomePaisInt: "Mauritania",
  sigla: "MR",
  codigo: "+222",
  mascara: " 00 00 0000"
},
{
  gentilico: "maiotense",
  nomePais: "Mayotte",
  nomePaisInt: "Mayotte",
  sigla: "YT",
  mascara: " 00 00 00"
},
{
  gentilico: "mexicana",
  nomePais: "México",
  nomePaisInt: "Mexico",
  sigla: "MX",
  codigo: "+52",
  mascara: " 00 00 0000"
},
{
  gentilico: "micronésia",
  nomePais: "Micronésia",
  nomePaisInt: "Micronesia",
  sigla: "FM",
  codigo: "+691",
  mascara: " 000 0000"
},
{
  gentilico: "moçambicana",
  nomePais: "Moçambique",
  nomePaisInt: "Mozambique",
  sigla: "MZ",
  codigo: "+258",
  mascara: " 00 000 000"
},
{
  gentilico: "moldavo",
  nomePais: "Moldova",
  nomePaisInt: "Moldova",
  sigla: "MD",
  codigo: "+373",
  mascara: " 0000 0000"
},
{
  gentilico: "monegasca",
  nomePais: "Mônaco",
  nomePaisInt: "Monaco",
  sigla: "MC",
  codigo: "+377",
  mascara: " 00 000 000"
},
{
  gentilico: "mongol",
  nomePais: "Mongólia",
  nomePaisInt: "Mongolia",
  sigla: "MN",
  codigo: "+976",
  mascara: " 00 00 0000"
},
{
  gentilico: "montenegra",
  nomePais: "Montenegro",
  nomePaisInt: "Montenegro",
  sigla: "ME",
  codigo: "+382",
  mascara: " 00 000 000"
},
{
  gentilico: "montserratiano",
  nomePais: "Montserrat",
  nomePaisInt: "Montserrat",
  sigla: "MS",
  codigo: "+1(664)",
  mascara: "000 0000"
},
{
  gentilico: "birmanês",
  nomePais: "Myanma",
  nomePaisInt: "Myanmar (Burma)",
  sigla: "MM",
  codigo: "+95",
  mascara: " 000 000"
},
{
  gentilico: "namíbia",
  nomePais: "Namíbia",
  nomePaisInt: "Namibia",
  sigla: "NA",
  codigo: "+264",
  mascara: " 00 000 0000"
},
{
  gentilico: "nauruana",
  nomePais: "Nauru",
  nomePaisInt: "Nauru",
  sigla: "NR",
  codigo: "+674",
  mascara: " 000 0000"
},
{
  gentilico: "nepalesa",
  nomePais: "Nepal",
  nomePaisInt: "Nepal",
  sigla: "NP",
  codigo: "+977",
  mascara: " 00 000 000"
},
{
  gentilico: "nicaraguense",
  nomePais: "Nicarágua",
  nomePaisInt: "Nicaragua",
  sigla: "NI",
  codigo: "+505",
  mascara: " 0000 0000"
},
{
  gentilico: "nigerina",
  nomePais: "Níger",
  nomePaisInt: "Niger",
  sigla: "NE",
  codigo: "+227",
  mascara: " 00 00 0000"
},
{
  gentilico: "nigeriana",
  nomePais: "Nigéria",
  nomePaisInt: "Nigeria",
  sigla: "NG",
  codigo: "+234",
  mascara: "(000) 000 0000"
},
{
  gentilico: "niueana",
  nomePais: "Niue",
  nomePaisInt: "Niue",
  sigla: "NU",
  codigo: "+683",
  mascara: " 0000"
},
{
  gentilico: "norueguesa",
  nomePais: "Noruega",
  nomePaisInt: "Norway",
  sigla: "NO",
  codigo: "+47",
  mascara: "(000) 00 000"
},
{
  gentilico: "caledônia",
  nomePais: "Nova Caledônia",
  nomePaisInt: "New Caledonia",
  sigla: "NC",
  codigo: "+687",
  mascara: " 00 0000"
},
{
  gentilico: "neozelandesa",
  nomePais: "Nova Zelândia",
  nomePaisInt: "New Zealand",
  sigla: "NZ",
  codigo: "+64",
  mascara: "(000) 000 0000"
},
{
  gentilico: "omani",
  nomePais: "Omã",
  nomePaisInt: "Oman",
  sigla: "OM",
  codigo: "+968",
  mascara: " 00 000 000"
},
{
  gentilico: "dos países baixos caribenhos",
  nomePais: "Países Baixos Caribenhos",
  nomePaisInt: "Caribbean Netherlands",
  sigla: "BQ",
  mascara: " 00 00 00"
},
{
  gentilico: "palauense",
  nomePais: "Palau",
  nomePaisInt: "Palau",
  sigla: "PW",
  codigo: "+680",
  mascara: " 000 0000"
},
{
  gentilico: "palestino",
  nomePais: "Palestina",
  nomePaisInt: "Palestine",
  sigla: "PS",
  codigo: "+970",
  mascara: " 00 000 0000"
},
{
  gentilico: "zona do canal do panamá",
  nomePais: "Panamá",
  nomePaisInt: "Panama",
  sigla: "PA",
  codigo: "+507",
  mascara: " 000 0000"
},
{
  gentilico: "pauásia",
  nomePais: "Papua-Nova Guiné",
  nomePaisInt: "Papua New Guinea",
  sigla: "PG",
  codigo: "+675",
  mascara: "(000) 00 000"
},
{
  gentilico: "paquistanesa",
  nomePais: "Paquistão",
  nomePaisInt: "Pakistan",
  sigla: "PK",
  codigo: "+92",
  mascara: "(000) 000 0000"
},
{
  gentilico: "paraguaia",
  nomePais: "Paraguai",
  nomePaisInt: "Paraguay",
  sigla: "PY",
  codigo: "+595",
  mascara: "(000) 000 000"
},
{
  gentilico: "peruana",
  nomePais: "Peru",
  nomePaisInt: "Peru",
  sigla: "PE",
  codigo: "+51",
  mascara: "(000) 000 000"
},
{
  gentilico: "franco polinésia",
  nomePais: "Polinésia Francesa",
  nomePaisInt: "French Polynesia",
  sigla: "PF",
  codigo: "+689",
  mascara: " 00 00 00"
},
{
  gentilico: "polonesa",
  nomePais: "Polônia",
  nomePaisInt: "Poland",
  sigla: "PL",
  codigo: "+48",
  mascara: "(000) 000 000"
},
{
  gentilico: "portorriquenha",
  nomePais: "Porto Rico",
  nomePaisInt: "Puerto Rico",
  sigla: "PR",
  mascara: " 00 00 00"
},
{
  gentilico: "portuguesa",
  nomePais: "Portugal",
  nomePaisInt: "Portugal",
  sigla: "PT",
  codigo: "+351",
  mascara: " 00 000 0000"
},
{
  gentilico: "catarense",
  nomePais: "Qatar",
  nomePaisInt: "Qatar",
  sigla: "QA",
  codigo: "+974",
  mascara: " 0000 0000"
},
{
  gentilico: "queniano",
  nomePais: "Quênia",
  nomePaisInt: "Kenya",
  sigla: "KE",
  codigo: "+254",
  mascara: " 000 000000"
},
{
  gentilico: "quirguiz",
  nomePais: "Quirguistão",
  nomePaisInt: "Kyrgyzstan",
  sigla: "KG",
  codigo: "+996",
  mascara: "(000) 000 000"
},
{
  gentilico: "centro africana",
  nomePais: "República Centro Africana",
  nomePaisInt: "Central African Republic",
  sigla: "CF",
  codigo: "+236",
  mascara: " 00 00 0000"
},
{
  gentilico: "congolesa",
  nomePais: "Congo",
  nomePaisInt: "Congo (DRC)",
  sigla: "CD",
  codigo: "+243",
  mascara: "(000) 000 000"
},
{
  gentilico: "dominicana",
  nomePais: "República Dominicana",
  nomePaisInt: "Dominican Republic",
  sigla: "DO",
  codigo: "+1(849)",
  mascara: "000 0000"
},
{
  gentilico: "tcheco",
  nomePais: "República Tcheca",
  nomePaisInt: "Czech Republic",
  sigla: "CZ",
  codigo: "+420",
  mascara: "(000) 000 000"
},
{
  gentilico: "romena",
  nomePais: "Romênia",
  nomePaisInt: "Romania",
  sigla: "RO",
  codigo: "+40",
  mascara: " 00 000 0000"
},
{
  gentilico: "ruandesa",
  nomePais: "Ruanda",
  nomePaisInt: "Rwanda",
  sigla: "RW",
  codigo: "+250",
  mascara: "(000) 000 000"
},
{
  gentilico: "russa",
  nomePais: "Rússia",
  nomePaisInt: "Russia",
  sigla: "RU",
  codigo: "+7",
  mascara: "(000) 000 00 00"
},
{
  gentilico: "saariano",
  nomePais: "Saara Ocidental",
  nomePaisInt: "Western Sahara",
  sigla: "EH",
  mascara: " 00 00 00"
},
{
  gentilico: "pedro miquelonense",
  nomePais: "Saint-Pierre e Miquelon",
  nomePaisInt: "St. Pierre & Miquelon",
  sigla: "PM",
  mascara: " 00 00 00"
},
{
  gentilico: "samoana",
  nomePais: "Samoa Americana",
  nomePaisInt: "American Samoa",
  sigla: "AS",
  codigo: "+1(684)",
  mascara: "000 0000"
},
{
  gentilico: "samoano",
  nomePais: "Samoa Ocidental",
  nomePaisInt: "Samoa",
  sigla: "WS",
  codigo: "+685",
  mascara: " 00 0000"
},
{
  gentilico: "samarinês",
  nomePais: "San Marino",
  nomePaisInt: "San Marino",
  sigla: "SM",
  codigo: "+378",
  mascara: " 0000 000000"
},
{
  gentilico: "helenense",
  nomePais: "Santa Helena",
  nomePaisInt: "St. Helena",
  sigla: "SH",
  codigo: "+290",
  mascara: " 0000"
},
{
  gentilico: "santa lucense",
  nomePais: "Santa Lúcia",
  nomePaisInt: "St. Lucia",
  sigla: "LC",
  codigo: "+1(758)",
  mascara: "000 0000"
},
{
  gentilico: "são bartolomeense",
  nomePais: "São Bartolomeu",
  nomePaisInt: "St. Barthélemy",
  sigla: "BL",
  mascara: " 00 00 00"
},
{
  gentilico: "são cristovense",
  nomePais: "São Cristóvão e Névis",
  nomePaisInt: "St. Kitts & Nevis",
  sigla: "KN",
  codigo: "+1(869)",
  mascara: "000 0000"
},
{
  gentilico: "são martinhense",
  nomePais: "São Martim",
  nomePaisInt: "St. Martin",
  sigla: "MF",
  mascara: " 00 00 00"
},
{
  gentilico: "são martinhense",
  nomePais: "São Martinho",
  nomePaisInt: "Sint Maarten",
  sigla: "SX",
  codigo: "+1(721)",
  mascara: "000 0000"
},
{
  gentilico: "são tomense",
  nomePais: "São Tomé e Príncipe",
  nomePaisInt: "São Tomé & Príncipe",
  sigla: "ST",
  codigo: "+239",
  mascara: " 00 00000"
},
{
  gentilico: "sao vicentino",
  nomePais: "São Vicente e Granadinas",
  nomePaisInt: "St. Vincent & Grenadines",
  sigla: "VC",
  codigo: "+1(784)",
  mascara: "000 0000"
},
{
  gentilico: "senegalesa",
  nomePais: "Senegal",
  nomePaisInt: "Senegal",
  sigla: "SN",
  codigo: "+221",
  mascara: " 00 000 0000"
},
{
  gentilico: "leonesa",
  nomePais: "Serra Leoa",
  nomePaisInt: "Sierra Leone",
  sigla: "SL",
  codigo: "+232",
  mascara: " 00 000000"
},
{
  gentilico: "sérvia",
  nomePais: "Sérvia",
  nomePaisInt: "Serbia",
  sigla: "RS",
  codigo: "+381",
  mascara: " 00 000 0000"
},
{
  gentilico: "síria",
  nomePais: "Síria",
  nomePaisInt: "Syria",
  sigla: "SY",
  codigo: "+963",
  mascara: " 00 0000 000"
},
{
  gentilico: "somali",
  nomePais: "Somália",
  nomePaisInt: "Somalia",
  sigla: "SO",
  codigo: "+252",
  mascara: " 0 000 000"
},
{
  gentilico: "cingalesa",
  nomePais: "Sri Lanka",
  nomePaisInt: "Sri Lanka",
  sigla: "LK",
  codigo: "+94",
  mascara: " 00 000 0000"
},
{
  gentilico: "suazi",
  nomePais: "Suazilândia",
  nomePaisInt: "Swaziland",
  sigla: "SZ",
  codigo: "+268",
  mascara: " 00 00 0000"
},
{
  gentilico: "sudanesa",
  nomePais: "Sudão",
  nomePaisInt: "Sudan",
  sigla: "SD",
  codigo: "+249",
  mascara: " 00 000 0000"
},
{
  gentilico: "sul sudanês",
  nomePais: "Sudão do Sul",
  nomePaisInt: "South Sudan",
  sigla: "SS",
  codigo: "+211",
  mascara: " 00 000 0000"
},
{
  gentilico: "sueca",
  nomePais: "Suécia",
  nomePaisInt: "Sweden",
  sigla: "SE",
  codigo: "+46",
  mascara: " 00 000 0000"
},
{
  gentilico: "suíça",
  nomePais: "Suíça",
  nomePaisInt: "Switzerland",
  sigla: "CH",
  codigo: "+41",
  mascara: " 00 000 0000"
},
{
  gentilico: "surinamesa",
  nomePais: "Suriname",
  nomePaisInt: "Suriname",
  sigla: "SR",
  codigo: "+597",
  mascara: " 000 000"
},
{
  gentilico: "svalbardense",
  nomePais: "Svalbard",
  nomePaisInt: "Svalbard & Jan Mayen",
  sigla: "SJ",
  mascara: " 00 00 00"
},
{
  gentilico: "tadjique",
  nomePais: "Tadjiquistão",
  nomePaisInt: "Tajikistan",
  sigla: "TJ",
  codigo: "+992",
  mascara: " 00 000 0000"
},
{
  gentilico: "tailandesa",
  nomePais: "Tailândia",
  nomePaisInt: "Thailand",
  sigla: "TH",
  codigo: "+66",
  mascara: " 00 000 000"
},
{
  gentilico: "taiwanês",
  nomePais: "Taiwan",
  nomePaisInt: "Taiwan",
  sigla: "TW",
  codigo: "+886",
  mascara: " 0000 0000"
},
{
  gentilico: "tanzaniana",
  nomePais: "Tanzânia",
  nomePaisInt: "Tanzania",
  sigla: "TZ",
  codigo: "+255",
  mascara: " 00 000 0000"
},
{
  gentilico: "timorense",
  nomePais: "Timor-Leste",
  nomePaisInt: "Timor Leste",
  sigla: "TL",
  codigo: "+670",
  mascara: " 000 00000"
},
{
  gentilico: "togolesa",
  nomePais: "Togo",
  nomePaisInt: "Togo",
  sigla: "TG",
  codigo: "+228",
  mascara: " 00 000 000"
},
{
  gentilico: "tonganesa",
  nomePais: "Tonga",
  nomePaisInt: "Tonga",
  sigla: "TO",
  codigo: "+676",
  mascara: " 00000"
},
{
  gentilico: "trinitário tobagense",
  nomePais: "Trinidad e Tobago",
  nomePaisInt: "Trinidad & Tobago",
  sigla: "TT",
  codigo: "+1(868)",
  mascara: "000 0000"
},
{
  gentilico: "tristanita",
  nomePais: "Tristão da Cunha",
  nomePaisInt: "Tristan da Cunha",
  sigla: "TA",
  mascara: " 00 00 00"
},
{
  gentilico: "tunisiana",
  nomePais: "Tunísia",
  nomePaisInt: "Tunisia",
  sigla: "TN",
  codigo: "+216",
  mascara: " 00 000 000"
},
{
  gentilico: "turcomana",
  nomePais: "Turcomenistão",
  nomePaisInt: "Turkmenistan",
  sigla: "TM",
  codigo: "+993",
  mascara: " 0 000 0000"
},
{
  gentilico: "turca",
  nomePais: "Turquia",
  nomePaisInt: "Turkey",
  sigla: "TR",
  codigo: "+90",
  mascara: "(000) 000 0000"
},
{
  gentilico: "tuvaluana",
  nomePais: "Tuvalu",
  nomePaisInt: "Tuvalu",
  sigla: "TV",
  codigo: "+688",
  mascara: " 00000"
},
{
  gentilico: "ucraniana",
  nomePais: "Ucrânia",
  nomePaisInt: "Ukraine",
  sigla: "UA",
  codigo: "+380",
  mascara: "(00)000 00 00"
},
{
  gentilico: "ugandense",
  nomePais: "Uganda",
  nomePaisInt: "Uganda",
  sigla: "UG",
  codigo: "+256",
  mascara: "(000) 000 000"
},
{
  gentilico: "uruguaia",
  nomePais: "Uruguai",
  nomePaisInt: "Uruguay",
  sigla: "UY",
  codigo: "+598",
  mascara: " 0 000 00 00"
},
{
  gentilico: "uzbeque",
  nomePais: "Uzbequistão",
  nomePaisInt: "Uzbekistan",
  sigla: "UZ",
  codigo: "+998",
  mascara: " 00 000 0000"
},
{
  gentilico: "vanuatuense",
  nomePais: "Vanuatu",
  nomePaisInt: "Vanuatu",
  sigla: "VU",
  codigo: "+678",
  mascara: " 00000"
},
{
  gentilico: "vaticano",
  nomePais: "Vaticano",
  nomePaisInt: "Vatican City",
  sigla: "VA",
  codigo: "+39",
  mascara: " 6 698 00000"
},
{
  gentilico: "venezuelana",
  nomePais: "Venezuela",
  nomePaisInt: "Venezuela",
  sigla: "VE",
  codigo: "+58",
  mascara: "(000) 000 0000"
},
{
  gentilico: "vietnamita",
  nomePais: "Vietnã",
  nomePaisInt: "Vietnam",
  sigla: "VN",
  codigo: "+84",
  mascara: "(000) 0000 000"
},
{
  gentilico: "wallis futunense",
  nomePais: "Wallis e Futuna",
  nomePaisInt: "Wallis & Futuna",
  sigla: "WF",
  codigo: "+681",
  mascara: " 00 0000"
},
{
  gentilico: "zambiana",
  nomePais: "Zâmbia",
  nomePaisInt: "Zambia",
  sigla: "ZM",
  codigo: "+260",
  mascara: " 00 000 0000"
},
{
  gentilico: "zimbabuana",
  nomePais: "Zimbábue",
  nomePaisInt: "Zimbabwe",
  sigla: "ZW",
  codigo: "+263",
  mascara: " 0 000000"
}
]
