import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { UserProfileComponent } from './user-profile.component';
import { QRCodeModule } from 'angularx-qrcode';
import { QRCodeModalComponent } from './qr-code/qrcode-modal-component.component';
import { ModalModule } from 'src/app/user-profile/change-contacts-modal/modal.module';
import { ToastrModule } from 'ngx-toastr';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

const maskConfig: Partial<IConfig> = {
  validation: true, // Isso habilita a validação da máscara
};

@NgModule({
  declarations: [
    UserProfileComponent,
    QRCodeModalComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    ModalModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-center',
    }),
    BrowserAnimationsModule,
    NgxMaskModule.forRoot(maskConfig),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    QRCodeModule
  ]
})
export class UserProfileModule { }
