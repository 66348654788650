import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'qrcode-modal',
  templateUrl: './qrcode-modal.component.html',
  styleUrls: ['./qrcode-modal.component.css']
})
export class QRCodeModalComponent {
  @Input() url: string = '';

  constructor(public modal: NgbActiveModal) {}
}

