<!--
     Vamos controlar (mostrar/esconder)
     o modal através deste *ngIf
-->
<ng-container *ngIf="show">
  <div class="custom-modal">
    <ng-content></ng-content>
  </div>
  <div (click)="toggle()" class="overlay"></div>
</ng-container>
