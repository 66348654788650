import { Estilo } from "./style.model";

export interface IUserCss {
    estiloPreDefinido?: Estilo;
    corPrimaria?: string;
    corSecundaria?: string;
    direcaoDegrade?: string;
    formato?: string;
    espessura?: string;
    sombra?: string;
    preenchimento?: string;
    contorno?: string;
    callingCardImageUrl?: string;
    curvaBorda?: string;
    fonte?: string;
    negrito?: boolean;
    italico?: boolean;
    corFonte?: string;
    fontePerfil?: string;
    negritoPerfil?: boolean;
    italicoPerfil?: boolean;
    corFontePerfil?: string;
    cardBackgroundImageUrl?: string;
}

export class UserCss implements IUserCss {
    constructor(
        public estiloPreDefinido?: Estilo,
        public corPrimaria?: string,
        public corSecundaria?: string,
        public direcaoDegrade?: string,
        public formato?: string,
        public espessura?: string,
        public sombra?: string,
        public preenchimento?: string,
        public contorno?: string,
        public curvaBorda?: string,
        public callingCardImageUrl?: string,
        public fonte?: string,
        public negrito?: boolean,
        public italico?: boolean,
        public corFonte?: string,
        public fontePerfil?: string,
        public negritoPerfil?: boolean,
        public italicoPerfil?: boolean,
        public corFontePerfil?: string,
        public cardBackgroundImageUrl ?: string
    ) {
        this.negrito = false;
        this.italico = false;
        this.negritoPerfil = false;
        this.italicoPerfil = false;
    }
}
