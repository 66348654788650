<div class="background"
  [style]="userCss?.cardBackgroundImageUrl !== undefined ? 'background-image: url(' + userCss?.cardBackgroundImageUrl + ')' : 'background:' + getBackground()"
  *ngIf="profileExists">
  <div [style.border-radius]="userCss?.curvaBorda + 'px ' + userCss?.curvaBorda + 'px 0px 0px'"
    *ngIf="profiles.length > 0 && profileLimits.professionalView && hasSomeUserVisible"
    class="switch-profiles-container" [style.box-shadow]="getBoxShadowCabecalho()">
    <div class="profile-selector"
      [style.border-bottom]="'2px solid ' + getProfileTabsBg('border', selectedProfile.indicator === -1) "
      [style.background]="getProfileTabsBg('bg', selectedProfile.indicator === -1)"
      [style.color]="getProfileTabsBg('color', selectedProfile.indicator === -1)" (click)="changeSelectedProfile(-1)"
      [style.border-radius]="'0px 0px 0px ' + userCss?.curvaBorda + 'px'">
      <i class=" bi bi-person-fill"></i>
      Pessoal
    </div>

    <ng-container *ngFor="let profile of profiles; index as i">
      <div *ngIf="profile.visible === null || profile.visible" class="profile-selector"
        [style.border-bottom]="'2px solid ' + getProfileTabsBg('border', selectedProfile.indicator === i) "
        [style.background]="getProfileTabsBg('bg', selectedProfile.indicator === i)"
        [style.color]="getProfileTabsBg('color', selectedProfile.indicator === i)" (click)="changeSelectedProfile(i)"
        [style.border-radius]="'0px ' + '0px ' + userCss?.curvaBorda + 'px 0px'">
        <i class="bi bi-briefcase-fill"></i>Corporativo
      </div>
    </ng-container>
  </div>

  <div class="content">
    <div class="profile-container" [style.background-image]="getBackgroundPerfil()"
      [style.border-radius]="!selectedProfile.data.header.visible && selectedProfile.data.header.text && selectedProfile.data.header.text.startsWith('\<br\>') ? '8px 8px 0 0' : '8px'">
      <img [src]="selectedProfile.data.uriImageProfile" alt="Imagem de Perfil" class="imagem-perfil" id="imageProfile"
        *ngIf="selectedProfile.data.showImageProfile">

      <section class="profile-center-section">
        <div class="bio-container">
          <span class="nome-perfil-text" [style.color]="userCss?.corFontePerfil"
            [style.font-family]="userCss?.fontePerfil" [style.font-weight]="userCss?.negritoPerfil ? 'bold' : undefined"
            [style.font-style]="userCss?.italicoPerfil ? 'italic' : undefined">
            {{ selectedProfile.data.name }}
          </span>

          <span class="biografia-text" [style.color]="userCss?.corFontePerfil"
            [style.font-family]="userCss?.fontePerfil" [style.font-weight]="userCss?.negritoPerfil ? 'bold' : undefined"
            [style.font-style]="userCss?.italicoPerfil ? 'italic' : undefined">
            {{ selectedProfile.data.bio }}
          </span>
        </div>

        <div class="principal-contacts-container">
          <div class="contact-item-container" *ngIf="principalEmailContact" (click)="openShowMoreEmailsPopup()">
            <i class="bi bi-envelope email-icon" [style.color]="userCss?.corFontePerfil"></i>

            <div *ngIf="emailContacts.length >= 1 && showMoreEmailsPopupOpened"
              class="additional-email-contact-container">
              <div class="additional-email" *ngFor="let emailContact of emailContacts"
                [style.border-bottom]="emailContact === emailContacts[emailContacts.length - 1] ? 'none' : '1px solid #d6d6d6'">
                <div class="email-value-container">
                  <a class="additional-email-label" href="mailto:{{emailContact}}"
                    [style.font-family]="userCss?.fontePerfil">
                    {{ emailContact }}
                  </a>
                </div>

                <div class="email-actions-container">
                  <button class="action-button" (click)="copyPhoneToClipboard(emailContact)">
                    <i *ngIf="textCopied !== emailContact" class="bi bi-clipboard action-button-icon"></i>
                    <i *ngIf="textCopied === emailContact" class="bi bi-clipboard-check action-button-icon"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="contact-item-container" *ngIf="principalPhoneContact" (click)="openShowMorePhonesPopup()">
            <i class="bi bi-telephone phone-icon" [style.color]="userCss?.corFontePerfil"></i>

            <div *ngIf="phoneContacts.length >= 1 && showMorePhonesPopupOpened"
              class="additional-phone-contact-container">
              <div class="additional-phone" *ngFor="let phoneContact of phoneContacts"
                [style.border-bottom]="phoneContact === phoneContacts[phoneContacts.length - 1] ? 'none' : '1px solid #d6d6d6'">
                <div class="phone-value-container">
                  <span class="additional-phone-label" [style.font-family]="userCss?.fontePerfil">
                    {{ phoneContact }}
                  </span>
                </div>

                <div class="phone-actions-container">
                  <button class="action-button" (click)="copyPhoneToClipboard(phoneContact)">
                    <i *ngIf="textCopied !== phoneContact" class="bi bi-clipboard action-button-icon"></i>
                    <i *ngIf="textCopied === phoneContact" class="bi bi-clipboard-check action-button-icon"></i>
                  </button>

                  <button class="action-button" (click)="callToPhone(phoneContact)">
                    <i class="bi bi-telephone-outbound action-button-icon"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="contact-item-containerr" *ngIf="principalLocationContact" (click)="openShowMoreLocationsPopup()">
            <i class="bi bi-geo-alt email-icon" [style.color]="userCss?.corFontePerfil"></i>

            <div *ngIf="locationContacts.length >= 1 && showMoreLocationsPopupOpened"
              class="additional-location-contact-container">
              <div class="additional-email" *ngFor="let locationContact of locationContacts"
                [style.border-bottom]="locationContact === locationContacts[locationContacts.length - 1] ? 'none' : '1px solid #d6d6d6'">
                <div class="email-value-container">
                  <a class="additional-email-label" [style.font-family]="userCss?.fontePerfil" [href]="locationContact"
                    target="_blank">
                    Clique para abrir a localização no navegador
                  </a>
                </div>

                <div class="email-actions-container">
                  <button class="action-button" (click)="copyPhoneToClipboard(locationContact)">
                    <i *ngIf="textCopied !== locationContact" class="bi bi-clipboard action-button-icon"></i>
                    <i *ngIf="textCopied === locationContact" class="bi bi-clipboard-check action-button-icon"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="bt-perfil-container">
        <button class="menu-button" (click)="toggleMenuActions()" [style.border-color]="userCss?.corFontePerfil">
          <i class="bi bi-list" [style.color]="userCss?.corFontePerfil"></i>
        </button>

        <div *ngIf="menuActionsPopupOpened" class="menu-actions-container">
          <button type="button" class="menu-option-button" (click)="getHome()">
            <i class="action-menu-icon bi bi-house-door" [style.color]="'#000'"></i>
            <span class="action-menu-label">Editar Perfil</span>
          </button>

          <div class="contact-item" *ngFor="let emailContact of selectedProfile.data.emailContacts">
            <div class="contact-value-container">
              <i class="action-menu-icon bi bi-envelope" [style.color]="'#000'"></i>

              <a class="contact-item-label" href="mailto:{{emailContact}}" [style.font-family]="userCss?.fontePerfil">
                {{ emailContact }}
              </a>
            </div>

            <div class="email-actions-container">
              <button class="action-button" (click)="copyPhoneToClipboard(emailContact)">
                <i *ngIf="textCopied !== emailContact" class="bi bi-clipboard action-button-icon"></i>
                <i *ngIf="textCopied === emailContact" class="bi bi-clipboard-check action-button-icon"></i>
              </button>
            </div>
          </div>

          <div class="contact-item" *ngFor="let phoneContact of selectedProfile.data.phoneContacts">
            <div class="contact-value-container">
              <i class="action-menu-icon bi bi-phone" [style.color]="'#000'"></i>

              <span class="contact-item-label" [style.font-family]="userCss?.fontePerfil">
                {{ phoneContact }}
              </span>
            </div>

            <div class="phone-actions-container">
              <button class="action-button" (click)="copyPhoneToClipboard(phoneContact)">
                <i *ngIf="textCopied !== phoneContact" class="bi bi-clipboard action-button-icon"></i>
                <i *ngIf="textCopied === phoneContact" class="bi bi-clipboard-check action-button-icon"></i>
              </button>

              <button class="action-button" (click)="callToPhone(phoneContact)">
                <i class="bi bi-telephone-outbound action-button-icon"></i>
              </button>
            </div>
          </div>

          <div class="contact-item" *ngFor="let locationContact of selectedProfile.data.locations">
            <div class="contact-value-container">
              <i class="action-menu-icon bi bi-geo-alt" [style.color]="'#000'"></i>

              <a class="contact-item-label" style="color: #0d6efd !important" [style.font-family]="userCss?.fontePerfil"
                [href]="locationContact" target="_blank">
                Clique para abrir a localização no navegador
              </a>
            </div>

            <div class="email-actions-container">
              <button class="action-button" (click)="copyPhoneToClipboard(locationContact)">
                <i *ngIf="textCopied !== locationContact" class="bi bi-clipboard action-button-icon"></i>
                <i *ngIf="textCopied === locationContact" class="bi bi-clipboard-check action-button-icon"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="conteiners descricao-profile-container" [style.box-shadow]="userCss?.sombra"
      [style.background]="getPreenchimentoCabecalho()" [style.box-shadow]="getBoxShadowCabecalho()"
      *ngIf="selectedProfile.data.header.visible && selectedProfile.data.header.text && !selectedProfile.data.header.text.startsWith('\<br\>')">
      <span class="descricao-perfil" [style.font-family]="userCss?.fontePerfil"
        [innerHTML]="selectedProfile.data.header.text">
        {{ selectedProfile.data.header.text }}</span>
    </div>

    <div class="icones">
      <button type="button" class="icon-button" *ngIf="containsSocialNetwork('instagram')"
        (click)="goToUrl('instagram')"><i class="bi bi-instagram icon"
          [style.color]="userCss?.preenchimento"></i></button>
      <button type="button" class="icon-button" *ngIf="containsSocialNetwork('whatsapp')"
        (click)="goToUrl('whatsapp')"><i class="bi bi-whatsapp icon"
          [style.color]="userCss?.preenchimento"></i></button>
      <button type="button" class="icon-button" *ngIf="containsSocialNetwork('telegram')"
        (click)="goToUrl('telegram')"><i class="bi bi-telegram icon"
          [style.color]="userCss?.preenchimento"></i></button>
      <button type="button" class="icon-button" *ngIf="containsSocialNetwork('linkedin')"
        (click)="goToUrl('linkedin')"><i class="bi bi-linkedin icon"
          [style.color]="userCss?.preenchimento"></i></button>
      <button type="button" class="icon-button" *ngIf="containsSocialNetwork('facebook')"
        (click)="goToUrl('facebook')"><i class="bi bi-facebook icon"
          [style.color]="userCss?.preenchimento"></i></button>
      <button type="button" class="icon-button" *ngIf="containsSocialNetwork('twitter')" (click)="goToUrl('twitter')"><i
          class="bi bi-twitter-x icon" [style.color]="userCss?.preenchimento"></i></button>
      <button type="button" class="icon-button" *ngIf="containsSocialNetwork('youtube')" (click)="goToUrl('youtube')"><i
          class="bi bi-youtube icon" [style.color]="userCss?.preenchimento"></i></button>
      <button type="button" class="icon-button" *ngIf="containsSocialNetwork('tik tok')" (click)="goToUrl('tiktok')"><i
          class="bi bi-tiktok icon" [style.color]="userCss?.preenchimento"></i></button>
      <button type="button" class="icon-button" *ngIf="containsSocialNetwork('twitch')" (click)="goToUrl('twitch')"><i
          class="bi bi-twitch icon" [style.color]="userCss?.preenchimento"></i></button>
      <button type="button" class="icon-button" *ngIf="containsSocialNetwork('pinterest')"
        (click)="goToUrl('pinterest')"><i class="bi bi-pinterest icon"
          [style.color]="userCss?.preenchimento"></i></button>
    </div>

    <div class="link-buttons-container">
      <div class="button-container" *ngFor="let button of selectedProfile.data.listButtons"
        [style.display]="button.visible ? 'block' : 'none'">
        <iframe [style.border-radius]="userCss?.curvaBorda + 'px '" [style.box-shadow]="userCss?.sombra"
          allowFullScreen="true" [style.border-width]="userCss?.espessura" [style.border-color]="userCss?.contorno"
          [style.border]="userCss?.espessura + 'px solid ' + userCss?.contorno" class="video-link-iframe"
          [style.border-style]="'solid'" *ngIf="button.visible && button.type === 'VIDEO'" [src]="button.formattedLink">
        </iframe>

        <a *ngIf="(!button.type || button.type === 'DEFAULT') && button.visible && button.title" class="button-link"
          [href]="getLink(button.link)" target="_blank" (click)="saveLinkMetrics(button.link)"
          [style.background]="button.layout !== 'HIGHLIGHTED' ? userCss?.preenchimento : ''"
          [style.border-radius]="button.layout === 'HIGHLIGHTED' ? userCss?.curvaBorda + 'px ' + userCss?.curvaBorda + 'px 0px 0px' : userCss?.curvaBorda + 'px '"
          [style.border-style]="'solid'" [style.border-width]="userCss?.espessura + 'px'"
          [style.box-shadow]="userCss?.sombra" [style.border-color]="userCss?.contorno"
          [style.background-image]="button.image && button.layout === 'HIGHLIGHTED' ? 'url(' + button.image + ')' : ''"
          [ngClass]="button.layout === 'HIGHLIGHTED' ? 'highlighted-button' : ''">

          <img [src]="button.image" alt="Imagem do botão" class="button-image"
            *ngIf="button.layout === 'CLASSIC' && button.image">

          <div class="link-button-title" *ngIf="!button.layout || button.layout === 'CLASSIC'">
            <span [style.color]="userCss?.corFonte" [style.font-family]="userCss?.fonte"
              [style.font-weight]="userCss?.negrito ? 'bold' : undefined"
              [style.font-style]="userCss?.italico ? 'italic' : undefined"
              [ngClass]="button.layout === 'HIGHLIGHTED' ? 'higlighted-left-title' : ''" [style.background-color]="userCss?.preenchimento && button.layout === 'HIGHLIGHTED' ? userCss?.preenchimento
              : ''">
              {{ button.title }}
            </span>
          </div>

          <div class="button-icon-container" *ngIf="!button.layout || button.layout === 'CLASSIC'">
            <i class="bi bi-chevron-right arrow" [style.color]="userCss?.corFonte"></i>
          </div>
        </a>

        <a class="highlighted-button-title-container" [href]="getLink(button.link)" target="_blank"
          (click)="saveLinkMetrics(button.link)"
          *ngIf="button.layout === 'HIGHLIGHTED' && (button.type === 'DEFAULT' || !button.type) && button.visible"
          [style.background-color]="userCss?.preenchimento" [style.box-shadow]="userCss?.sombra"
          [style.border-style]="'solid'" [style.border-color]="userCss?.contorno" [style.border-top-width]="'0px'"
          [style.border-width]="userCss?.espessura + 'px'"
          [style.border-radius]="'0px 0px ' + userCss?.curvaBorda + 'px ' + userCss?.curvaBorda + 'px'"
          [style.border-top]="'0px'" [style.color]="userCss?.corFonte">
          <span class="highlighted-button-title" [style.color]="userCss?.corFonte" [style.font-family]="userCss?.fonte"
            [style.font-weight]="userCss?.negrito ? 'bold' : undefined"
            [style.font-style]="userCss?.italico ? 'italic' : undefined">
            {{ button.title }}
          </span>

          <div class="button-icon-container">
            <i class="bi bi-chevron-right preview-arrow" [style.color]="userCss?.corFonte"></i>
          </div>
        </a>

        <a [href]="button.link" target="_blank" class="product-link-item" *ngIf="button.type === 'PRODUCT'"
          [style.background-color]="userCss?.preenchimento" [style.box-shadow]="userCss?.sombra"
          style="border-style: solid" [style.border-width]="userCss?.espessura + 'px'"
          [style.border-radius]="userCss?.curvaBorda + 'px'" [style.border-color]="userCss?.contorno">
          <div [style.background-image]="'url(' + button.image + ')'" alt="product-image"
            [style.border-radius]="userCss?.curvaBorda + 'px'" class="product-image"></div>

          <section class="product-heading-section">
            <h1 class="product-title" [style.color]="userCss?.corFonte" [style.font-family]="userCss?.fonte">{{
              button.title }}
            </h1>

            <div class="product-description" [innerHTML]="button.description">{{ button.description }}</div>
          </section>

          <section class="product-price-section">
            <span [style.color]="getColorContrast(userCss?.corFonte || '#fff', 0.1)" class="product-price">{{
              button.productValue
              ? button.productValue.toLocaleString(undefined, {
              style: 'currency',
              currency: button.currency,
              minimumFractionDigits: 2
              })
              : 'Preço não informado'
              }}</span>
          </section>
        </a>
      </div>

      <div class="button-container" *ngIf="userProfile?.documentUser?.isReseller">
        <a class="button-link" [href]="getUserSaleLink()" target="_blank" (click)="saveLinkMetrics(getUserSaleLink())"
          [style.background]="userCss?.preenchimento" [style.border-radius]="userCss?.curvaBorda + 'px'"
          [style.border]="userCss?.espessura + 'px solid ' + userCss?.contorno">
          <img src="/assets/img/sluper_logo.svg" alt="" class="sale-button-sluper-logo">

          <div class="link-button-title">
            <span [style.color]="userCss?.corFonte" [style.font-family]="userCss?.fonte"
              [style.font-weight]="userCss?.negrito ? 'bold' : undefined"
              [style.font-style]="userCss?.italico ? 'italic' : undefined">
              Compre seu Sluper com Desconto!
            </span>
          </div>

          <div class=" button-icon-container">
            <i class="bi bi-chevron-right arrow" [style.color]="userCss?.corFonte"></i>
          </div>
        </a>
      </div>
    </div>

    <div class="sluper-signature-container">
      <span class="sluper-signature">Powered by</span>
      <a class="sluper-signature-logo-background" href="https://sluper.digital/">
        <img class="sluper-signature-logo" src="/assets/img/logo-sluper-digital.svg" alt="">
      </a>
    </div>

    <div class="overlay"
      *ngIf="(phoneContacts.length >= 1 && showMorePhonesPopupOpened) || (emailContacts.length >= 1 && showMoreEmailsPopupOpened) || (locationContacts.length >= 1 && showMoreLocationsPopupOpened) || menuActionsPopupOpened"
      (click)="closeShowMoreInfosPopups()">
    </div>
  </div>

  <div class="change-contacts-container">
    <div class="change-contacts-content" *ngIf="addContact && changeContactsButtonOpened">
      <div class="call-to-action-container">
        <span class="sluper-connect-label">
          Sluper Networking
        </span>
        <span class="call-to-action">
          Envie seus contatos para {{ selectedProfile.data.name }}
        </span>
      </div>

      <button (click)="openSendContactsModal()" class="change-contacts-button">Enviar</button>

      <button class="close-change-contacts-button" (click)="closeCangeContactsButton()">
        <i class="bi bi-x"></i>
      </button>
    </div>

    <div [style.border]="'1px solid ' + getProfileTabsBg('border', true)" [style.border-bottom]="'none'"
      [style.background]="getProfileTabsBg('bg', true)" class="menu"
      [style.border-radius]="userCss?.curvaBorda + 'px ' + userCss?.curvaBorda + 'px ' + '0px 0px'">
      <button class="menu-item" (click)="openQRCodeModal()" [style.color]="userCss?.corFonte"
        [style.font-family]="userCss?.fonte" [style.border-color]="getProfileTabsBg('border', true)">
        <i class="bi bi-qr-code"></i>

        <span class="menu-title">Qr Code</span>
      </button>

      <button class="menu-item" (click)="compartilhar()" [style.color]="userCss?.corFonte"
        [style.font-family]="userCss?.fonte" [style.border-color]="getProfileTabsBg('border', true)">
        <i class="bi bi bi-share"></i>

        <span class="menu-title">Compartilhar</span>
      </button>

      <button class="menu-item" (click)="gerarVcard()" [style.color]="userCss?.corFonte"
        [style.font-family]="userCss?.fonte" [style.border-color]="getProfileTabsBg('border', true)">
        <i class="bi bi-person-vcard"></i>

        <span class="menu-title">Salvar contato</span>
      </button>
    </div>
  </div>

  <app-modal #modal>
    <div class="change-contacts-modal-container">
      <img [src]="selectedProfile.data.uriImageProfile" alt="Imagem de Perfil" class="modal-profile-pic"
        id="imageProfile">

      <div class="modal-header">
        <div class="modal-close-button-container">
          <button type="button" class="modal-close-button" (click)="modal.toggle()">
            <i class="bi bi-x modal-close-icon"></i>
          </button>
        </div>

        <span class="modal-header-title">Enviar contatos para {{ selectedProfile.data.name }}</span>
      </div>

      <div class="change-contacts-form-container">
        <form class="change-contacts-form" [formGroup]="sendContactsForm" (ngSubmit)="sendContacts()">
          <div class="input-div">
            <label for="nome" class="labels-text" translate>Nome:</label>
            <div class="input-group inputs">
              <div class="input-group-prepend">
                <span [style.border-color]="sendContactsForm.controls['name'].value.length < 1 ? 'red' : ''"
                  class="input-group-text input-prepend input-icon" id="basic-addon1">
                  <i class="bi bi-pencil"></i>
                </span>
              </div>

              <input type="text" class="form-control input-text" id="name" name="name" formControlName="name"
                [style.border-color]="sendContactsForm.controls['name'].value.length < 1 ? 'red' : ''">
            </div>
          </div>

          <div class="input-div">
            <label for="email" class="labels-text" translate>Email:</label>
            <div class="input-group inputs">
              <div class="input-group-prepend">
                <span class="input-group-text input-prepend input-icon" id="basic-addon3"
                  [style.border-color]="(sendContactsForm.controls['email'].value.length < 1 && sendContactsForm.controls['phone'].value.length < 1) || sendContactFormEmailError ? 'red' : ''">
                  <i class="bi bi-envelope"></i>
                </span>
              </div>

              <input type="text" class="form-control input-text" id="email" name="email" formControlName="email"
                [style.border-color]="(sendContactsForm.controls['email'].value.length < 1 && sendContactsForm.controls['phone'].value.length < 1) || sendContactFormEmailError ? 'red' : ''"
                (input)="setConditionallyRequiredValidator($event)">
            </div>

            <span class="invalidEmailErrorMessage" *ngIf="sendContactFormEmailError">E-mail inválido</span>
          </div>

          <div class="input-div">
            <label for="phone" class="labels-text" translate>Telefone:</label>
            <div class="input-group inputs">
              <div class="input-group-prepend">
                <span class="input-group-text input-prepend input-icon" id="basic-addon4"
                  [style.border-color]="sendContactsForm.controls['email'].value.length < 1 && sendContactsForm.controls['phone'].value.length < 1 ? 'red' : ''">
                  <i class="bi bi-telephone"></i>
                </span>
              </div>

              <select class="form-select input-text" name="countryPhone"
                [style.border-color]="sendContactsForm.controls['email'].value.length < 1 && sendContactsForm.controls['phone'].value.length < 1 ? 'red' : ''"
                (input)="handlePhoneCountryInput($event)" formControlName="countryPhone" [style.max-width]="'160px'">
                <option [ngValue]="pais" *ngFor="let pais of paises">{{ pais.nomePais }} {{
                  pais.codigo }}
                </option>
              </select>

              <input type="text" class="form-control input-text" id="phone" name="phone" formControlName="phone"
                [mask]="sendContactsForm.controls['countryPhone'].value?.mascara"
                [style.border-color]="sendContactsForm.controls['email'].value.length < 1 && sendContactsForm.controls['phone'].value.length < 1 ? 'red' : ''"
                (input)="setConditionallyRequiredValidator($event)">
            </div>
          </div>

          <div class="input-div">
            <label for="company" class="labels-text" translate>Empresa:</label>
            <div class="input-group inputs">
              <div class="input-group-prepend">
                <span class="input-group-text input-prepend input-icon" id="basic-addon4">
                  <i class="bi bi-suitcase-lg"></i>
                </span>
              </div>

              <input type="text" class="form-control input-text" id="company" name="company" formControlName="company">
            </div>
          </div>

          <div class="input-div">
            <label for="position" class="labels-text" translate>Cargo:</label>
            <div class="input-group inputs">
              <div class="input-group-prepend">
                <span class="input-group-text input-prepend input-icon" id="basic-addon4">
                  <i class="bi bi-person-square"></i>
                </span>
              </div>

              <input type="text" class="form-control input-text" id="position" name="position"
                formControlName="position">
            </div>
          </div>

          <label for="accept-receive-emails-input" class="accept-receive-emails-label">
            <input id="accept-receive-emails-input" type="checkbox" formControlName="acceptSendingEmails">

            Aceito compartilhar meus dados com {{nome}} e receber e-mails da Sluper
          </label>

          <button class="change-contacts-save-button" type="submit"
            [disabled]="!sendContactsForm.controls.acceptSendingEmails.value">
            Enviar
          </button>
        </form>
      </div>
    </div>
  </app-modal>
</div>