import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IUserProfileWithCSS } from '../model/user-profile-css.model';
import { IUserUriRequest } from '../model/user-uri-request.model';
import { environment } from 'src/environments/environment';
import { IImageResponse } from '../model/image-response.model';
import { IMetricRequest } from '../model/metric-request.model';
import { ISendContactsRequest } from '../model/send-contacts.model';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  baseUrl = environment.baseurl;

  constructor(protected http: HttpClient) { }

  userByUri(request: IUserUriRequest): Observable<HttpResponse<IUserProfileWithCSS>> {
    return this.http.post<IUserProfileWithCSS>(this.baseUrl + "/userByUri", request, { observe: 'response' });
  }

  getImageProfile(idUser: string): Observable<HttpResponse<IImageResponse>> {
    return this.http.get<IImageResponse>(this.baseUrl + "/imageProfile/"+idUser, { observe: 'response' });
  }

  createMetric(request: IMetricRequest): Observable<HttpResponse<void>> {
    return this.http.put<void>(this.baseUrl + "/metric", request, { observe: 'response' });
  }

  sendContacts(request: ISendContactsRequest): Observable<HttpResponse<any>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.put<any>(this.baseUrl + "/leads", request, { headers: headers, observe: 'response' });
  }
}
